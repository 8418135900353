var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"bg-variant":"light"}},[(_vm.showWorkGroupSelection)?_c('div',[_c('work-group-select',{on:{"selectedItems":_vm.setWorkGroupId}})],1):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.selected_work_group))]),(_vm.showProfileSelection)?_c('div',[_c('profile-select',{on:{"selectedItems":_vm.setProfileId}})],1):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.selected_profile))]),_c('b-form-group',{staticClass:"mb-0",attrs:{"label-cols-lg":"3","label":"Actor Role","label-size":"lg","label-class":"font-weight-bold pt-0"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Role:","label-cols-sm":"3","label-align-sm":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{staticClass:"pt-2",attrs:{"options":['admin', 'manager', 'worker', 'auditor'],"aria-describedby":ariaDescribedby},model:{value:(_vm.attr_copy['role']),callback:function ($$v) {_vm.$set(_vm.attr_copy, 'role', $$v)},expression:"attr_copy['role']"}})]}}])}),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Can Read:","label-cols-sm":"3","label-align-sm":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"name":"can-read-check-button","switch":"","aria-describedby":ariaDescribedby},model:{value:(_vm.attr_copy['can-read']),callback:function ($$v) {_vm.$set(_vm.attr_copy, 'can-read', $$v)},expression:"attr_copy['can-read']"}})]}}])}),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Can Create:","label-cols-sm":"3","label-align-sm":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"name":"can-create-check-button","switch":"","aria-describedby":ariaDescribedby},model:{value:(_vm.attr_copy['can-create']),callback:function ($$v) {_vm.$set(_vm.attr_copy, 'can-create', $$v)},expression:"attr_copy['can-create']"}})]}}])}),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Can Update:","label-cols-sm":"3","label-align-sm":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"name":"can-update-check-button","switch":"","aria-describedby":ariaDescribedby},model:{value:(_vm.attr_copy['can-update']),callback:function ($$v) {_vm.$set(_vm.attr_copy, 'can-update', $$v)},expression:"attr_copy['can-update']"}})]}}])}),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Can Destroy:","label-cols-sm":"3","label-align-sm":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"name":"can-destroy-check-button","switch":"","aria-describedby":ariaDescribedby},model:{value:(_vm.attr_copy['can-destroy']),callback:function ($$v) {_vm.$set(_vm.attr_copy, 'can-destroy', $$v)},expression:"attr_copy['can-destroy']"}})]}}])})],1),_c('br'),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"block":"","variant":"primary","disabled":_vm.disableAdd},on:{"click":_vm.callApi}},[_vm._v("Add")])],1),_c('b-col',[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":_vm.callCancel}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }