<template>
 <div>
  <b-card bg-variant="light">
    <div v-if="showWorkGroupSelection">
      <work-group-select @selectedItems="setWorkGroupId" />
    </div>
    <h1>{{ selected_work_group }}</h1>
    <div v-if="showProfileSelection">
      <profile-select @selectedItems="setProfileId" />
    </div>
    <h1>{{ selected_profile }}</h1>
    <b-form-group
      label-cols-lg="3"
      label="Actor Role"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Role:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          class="pt-2"
          v-model="attr_copy['role']"
          :options="['admin', 'manager', 'worker', 'auditor']"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group
        label="Can Read:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-model="attr_copy['can-read']"
          name="can-read-check-button"
          switch
          :aria-describedby="ariaDescribedby"
        >
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Can Create:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-model="attr_copy['can-create']"
          name="can-create-check-button"
          switch
          :aria-describedby="ariaDescribedby"
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Can Update:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-model="attr_copy['can-update']"
          name="can-update-check-button"
          switch
          :aria-describedby="ariaDescribedby"
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Can Destroy:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-model="attr_copy['can-destroy']"
          name="can-destroy-check-button"
          switch
          :aria-describedby="ariaDescribedby"
        >
        </b-form-checkbox>
      </b-form-group>
      
    </b-form-group>
    <br>
    <b-row>
      <b-col>
        <b-button block variant="primary" :disabled="disableAdd" @click="callApi">Add</b-button>
      </b-col>
      <b-col>
        <b-button block variant="primary" @click="callCancel">Cancel</b-button>
      </b-col>
    </b-row>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import WorkGroupSelect from './WorkGroupSelect.vue'
import ProfileSelect from './ProfileSelect.vue'
import {amsMofifiedAttrs} from '@/utils'
import {amsSchema} from '@/schema'

export default {
  components: {
    WorkGroupSelect,
    ProfileSelect
  },
  props: {
    reroute: {
      Type: Boolean,
      default: true
    },
    ams_template: {
      Type: Object
    },
  },
  data () {
    return {
      ams_type: 'actor-roles',
      ams_api_path: 'api/admin/v1/',
      attr_copy: {},
      attr_orig: {},
      description: undefined,
      selected_work_group: undefined,
      selected_profile: undefined
    }
  },
  computed: {
    showWorkGroupSelection() {
      return !(this.attr_copy['work-group-id']?.length)
    },
    showProfileSelection() {
      return !(this.attr_copy['profile-id']?.length)
    },
    disableAdd() {
      return !(this.attr_copy['work-group-id']?.length && this.attr_copy['profile-id']?.length)
    }
  },
  methods: {
    setWorkGroupId(items) {
      this.attr_copy['work-group-id'] = items[0].id
      this.selected_work_group = items[0].attributes['name']
    },
    setProfileId(items) {
      this.attr_copy['profile-id'] = items[0].id
      this.selected_profile = items[0].attributes['nickname'] + ": " + items[0].attributes['unique-alias']
    },
    callApi () {
      let attr_params = amsMofifiedAttrs(this.attr_copy, this.attr_orig)
      http_v1.post (
        this.ams_api_path + this.ams_type.replace(/-/g, '_'), {
          ...attr_params
        }
      ).then(response => {
        if( response.data) {
          this.$emit('takeAction')
          if(this.reroute) {
            this.$router.go(-1)
          }
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callCancel() {
      this.$emit('noAction')
      if(this.reroute) {
        this.$router.go(-1)
      }
    }
  },
  watch: {
    ams_template(val) {
      if(val?.attributes) {
        this.selected_profile = undefined
        this.selected_work_group = undefined
        this.attr_copy = {...val.attributes}
      }
    }
  },
  mounted() {
    if(this.ams_template?.attributes) {
      this.attr_copy = {...this.ams_template.attributes}
    }
    else {
      const included_obj = amsSchema.find( 
        el =>  
        el.type === this.ams_type
      )
      this.attr_copy = {...included_obj.attributes}
    }
  }
}
</script>