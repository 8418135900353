<template>
  <ams-select-table
    :ams_api_path="ams_api_path"
    :ams_type="ams_type"
    :ams_fields="ams_fields"
    :pre_selected_items="pre_selection"
    :select_mode="mode_selection"
    @selectedRows="selectRows"
  />
</template>

<script>
import AmsSelectTable from './AmsSelectTable.vue'

export default {
  components: {
    AmsSelectTable
  },
  props: {
    pre_selection: {
      Type: Array
    }
  },
  data() {
    return {
      mode_selection: 'single',
      ams_type: 'profiles',
      ams_api_path: 'api/admin/v1',
      ams_fields: [
        { 
          key: 'attributes.nickname',
          label: 'Nickname',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.unique-alias',
          label: 'Alias ID',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.mobile',
          label: 'Mobile',
          sortable: true,
          filterable: true,
          class: 'text-right',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.location',
          label: 'Location',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        },
        {
          key: 'attributes.will-work',
          label: 'Will Work',
          sortable: true,
          filterable: true,
          sortDirection: 'asc'
        },
        {
          key: 'attributes.will-audit',
          label: 'Will Audit',
          sortable: true,
          filterable: true,
          sortDirection: 'asc'
        },
        { key: 'actions', label: 'Actions' }
      ],
      describe_belongs_to: [
        {
          prepend: ' ',
          type: 'users',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: ' of ',
          type: 'users',
          description_attr: 'email',
          append: ''
        }
      ]
    }
  },
  methods: {
    selectRows(items) {
      this.$emit('selectedItems', items)
    }
  }
}
</script>